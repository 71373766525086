import {useRouter} from 'next/router';
import React, {useState, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import NavContent from '../../../var/NavContent.json';
import userMenuOptions from '../data/UserMenuOptions.json';
import NavContext from '../data/NavContext';
import PastDueNotification from '../Notifications/PastDueNotification';

export default function NavRoot({children, className, forceLogin}) {
  const router = useRouter();

  // NavBar State
  const [menuList, setMenuList] = useState(NavContent);
  const [activeFilter, setActiveFilter] = useState('all categories');
  const [inputSearch, setInputSearch] = useState(router?.query?.search || '');
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const [isMenuPanelOpen, setIsMenuPanelOpen] = useState(false);
  const [isAccountPanelOpen, setIsAccountPanelOpen] = useState(false);
  const [userOptions] = useState(userMenuOptions);
  const [mockLogin, setMockLogin] = useState(false);
  const [showLoginPanel, setShowLoginPanel] = useState(false);

  // Mocking the login is only development feature, this will avoid to push the mock login to production
  useEffect(() => {
    if (window?.location.host === 'placeit.net') {
      setMockLogin(false);
    } else {
      setMockLogin(forceLogin);
    }
  }, [forceLogin]);

  // Memoized NavBar state to prevent unecesary renders.
  const value = useMemo(
    () => ({
      menuList,
      setMenuList,
      activeFilter,
      setActiveFilter,
      inputSearch,
      setInputSearch,
      isBannerVisible,
      setIsBannerVisible,
      isMenuPanelOpen,
      setIsMenuPanelOpen,
      isAccountPanelOpen,
      setIsAccountPanelOpen,
      userOptions,
      mockLogin,
      showLoginPanel,
      setShowLoginPanel,
    }),
    [
      menuList,
      activeFilter,
      inputSearch,
      isBannerVisible,
      isMenuPanelOpen,
      isAccountPanelOpen,
      userOptions,
      mockLogin,
      showLoginPanel,
    ]
  );

  // Pass the state value to the provider and wrap the nav element, so children can access the nav state.
  return (
    <NavContext.Provider value={value}>
      <PastDueNotification />
      <nav id="navbar" className={className}>
        {children}
      </nav>
    </NavContext.Provider>
  );
}

NavRoot.defaultProps = {
  children: null,
  className: null,
  forceLogin: false,
};
NavRoot.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  forceLogin: PropTypes.bool,
};

export const RootPlasmicData = {
  name: 'Nav.Root',
  props: {
    forceLogin: {
      type: 'boolean',
      defaultValue: false,
      displayName: 'Mock Login',
    },
    children: {
      type: 'slot',
      allowedComponents: ['Nav.Banner', 'Nav.MenuBar', 'Nav.SearchBar'],
      defaultValue: [
        {
          type: 'component',
          name: 'Nav.Banner',
        },
        {
          type: 'component',
          name: 'Nav.MenuBar',
        },
        {
          type: 'component',
          name: 'Nav.SearchBar',
        },
      ],
    },
  },
  defaultStyles: {backgroundColor: '#000000', zIndex: '50', width: '100%'},
  importPath: './components/NavBar/Root',
  isDefaultExport: true,
};
